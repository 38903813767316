import type { App } from 'vue'
import { createI18n } from 'vue-i18n'

const languages = ['hu', 'en']

const getLocale = () => {
  return 'hu';
  // fetch language from cookie
  const cookieLanguage = sessionStorage.getItem('language')
  // check if cookie is set
  if (cookieLanguage) {
    return cookieLanguage
  }
  // get language from navigator
  const language = navigator.language.toLowerCase()
  // check if language is supported
  return languages.find(lang => lang === language) ? language : 'en';
}

const i18n = createI18n({
  locale: getLocale(),
})

// export default i18n
export default (app: App<Element>): void => {
  app.use(i18n)
}
