export default {
    whatsincluded: {
        title: 'What\'s included?',
        storage: 'Storage',
        email: 'E-mail accounts',
        webspace: 'Webspace',
        dialog: {
          storage: {
            blocks: [
              'Storage space for personal and business use, with a large selection.',
              'Storing files in the cloud prevents them from being lost if the device is damaged.',
              'Aside from security, cloud storage also makes it easy to access your files from all of your devices.'
            ],
          },
          email: {
            blocks: [
              'The number of e-mail accounts you get depends on your plan.',
              'The smallest plan includes 3 email accounts, while larger plans are perfect for businesses, where sky is the limit.',
              'The default domain is lonic.me, but you can use your own, if you have one.'
            ],
          },
          webspace: {
            blocks: [
              'You get a subdomain at lonic.me, which you can use to host your own website.',
              'If you don\'t have a website, but you want one, feel free to contact us, and we\'ll send you an offer.',
              'You can also use your own domain name.'
            ]
          },
        },
        button: {
            info: 'info',
        },
      },
}