import store from '@/store';
import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/home/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('../views/auth/AuthView.vue')
  },
  {
    path: '/error/404',
    name: 'error-404',
    component: () => import('../views/error/404/NotFoundView.vue')
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('@/views/order/OrderView.vue'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/error/404'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (isLoginNeeded(to)) {
    return next('/auth');
  }

  return next();
});

function isLoginNeeded(to: RouteLocationNormalized): boolean {
  return !isLoggedIn() && (to.meta?.authRequired as boolean ?? false);
}

function isLoggedIn(): boolean {
  return store.getters['CurrentUser/isLoggedIn'];
}

export default router
