import huLocale from "./hu";
import enLocale from "./en";

export default {
    hu: {
        ...huLocale
    },
    en: {
        ...enLocale
    }
}