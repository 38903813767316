export default {
    home: {
        welcome: {
          subtitle: {
            flexible: ':Flexible: cloud storage for everyone.',
            secure: ':Secure: cloud storage for everyone.',
            modern: ':Modern: cloud storage for everyone.',
            simple: ':Simple: cloud storage for everyone.',
          }
        },
        about: {
          simple: {
            title: 'Simple.',
            blocks: [
              'Access your files from your phone, computer or the web, wherever you are.',
              'Our phone and desktop apps make it easy to access your data.',
            ],
          },
          secure: {
            title: 'Secure.',
            blocks: [
              'We only use the datacenters of trusted providers to store your data.',
              'Data is encrypted before it leaves your devices, so only you can access it.',
              'If you want to share some of your data with others, we provide a secure way to do so.',
            ]
          },
          modern: {
            title: 'Modern.',
            blocks: [
              'We use our own custom made technologies, so we can always improve our services without relying on others.',
              'Our apps are user friendly, easy to use, and well documented.'
            ]
          },
          flexible: {
            title: 'Flexible.',
            blocks: [
              'You can customize the size of your storage, to perfectly fit your needs.',
              'There are countless options to choose from, so you don\'t have to pay more than you really need.'
            ]
          }
        },
        buttons: {
          order: 'order',
          about: 'about us'
        }
    },
}