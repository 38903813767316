export default {
    namespaced: true,
    state: {
    },
    getters: {
        getCurrentUser(state: any) {
            return state?.currentUser;
        },
        isLoggedIn(state: any) {
            return !!state?.currentUser;
        }
    }
}