export default {
    home: {
        welcome: {
            subtitle: {
              flexible: ':Rugalmas: felhőtárhely mindenkinek.',
              secure: ':Biztonságos: felhőtárhely mindenkinek.',
              modern: ':Modern: felhőtárhely mindenkinek.',
              simple: ':Egyszerű: felhőtárhely mindenkinek.',
            }
        },
        about: {
            simple: {
              title: 'Egyszerű.',
              blocks: [
                'Férj hozzá fájljaidhoz telefonról, számítógépről, vagy a weben.',
                'A telefonos és számítógépes alkalmazásaink segítségével könnyedén elérheted fájljaid.',
              ],
            },
            secure: {
              title: 'Biztonságos.',
              blocks: [
                'Kizárólag megbízható szolgáltatók adatközpontjait használjuk a fájlok tárolására.',
                'A fájlok már azelőtt titkosítva vannak, hogy feltöltenéd őket, ezáltal rajtad kívül senki sem férhet hozzájuk.',
                'Ha egyes fájlokat meg szeretnél osztani másokkal, ezt biztonságos módon megteheted.',
              ],
            },
            modern: {
              title: 'Modern.',
              blocks: [
                'Saját technológiákat használunk, ezáltal harmadik felektől függetlenül tudjuk fejleszteni rendszereinket.',
                'Alkalmazásaink felhasználóbarátak, könnyen használhatóak, és jól dokumentáltak.',
              ]
            },
            flexible: {
              title: 'Rugalmas.',
              blocks: [
                'Személyre szabhatod a tárhelyed méretét, szükségeidnek megfelelően.',
                'Számtalan csomag közül választhatsz, ezáltal nem kell többet fizetned, mint amire valóban szükséged van.',
              ]
            }
        },
        buttons: {
            order: 'megrendelés',
            about: 'rólunk',
        },
    }
}