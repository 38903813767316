export default {
    whatsincluded: {
        title: 'Mit tartalmaz?',
        storage: 'Tárhely',
        email: 'E-mail fiókok',
        webspace: 'Webtárhely',
        dialog: {
          storage: {
            blocks: [
              'Magánszemélyeknek és cégeknek egyaránt, széles választékkal.',
              'A felhőben tárolt fájlok nincsenek eszközhöz kötve, akkor is biztonságban vannak, ha valamely készüléked elveszik, meghibásodik.',
              'A biztonság mellett egyszerű hozzáférést is biztosít bármely készülékedről.'
            ],
          },
          email: {
            blocks: [
              'A fiókok száma a kiválasztott csomagtól függ.',
              'A legkisebb csomag 3 e-mail fiókot tartalmaz, míg a nagyobb csomagok üzleti használatra is kiválóak.',
              'A fiókok alapból lonic.me végződést kapnak, viszont amennyiben rendelkezel saját domain névvel, azt is könnyedén beállíthatod.',
            ],
          },
          webspace: {
            blocks: [
              'Biztosítunk számodra egy lonic.me aldomaint, ahol weboldalaidat tárolhatod.',
              'Ha nem rendelkezel saját weboldallal, de szeretnél egyet, bátran keress minket árajánlatért.',
              'Az általunk biztosított aldomain helyett természetesen saját domain neved is használhatod.'
            ]
          },
        },
        button: {
            info: 'infó',
        },
    },
}